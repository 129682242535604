<template>
  <div
    :id="`m_input_${id}`"
    class="m-input"
    :class="[{ 'm-input--fullwidth': fullwidth }, `m-input--${size}`]"
    @click.stop="focusInput"
  >
    <div
      class="m-input__heading"
      :class="
        label ? 'm-input__heading--space-between' : 'm-input__heading--flex-end'
      "
    >
      <label v-if="label" :for="id" :class="`type--${size}`">
        {{ label }}
      </label>
      <m-icon
        v-if="info"
        :tooltip="getTooltip(info)"
        icon="info"
        :size="size"
      />
      <m-icon
        v-if="required"
        :tooltip="getTooltip(t('general.forms.required'))"
        icon="emergency"
        variant="error"
        :size="size"
      />
    </div>
    <div class="p-relative">
      <m-hint v-if="displayHints" id="m_hint_create_view_name" />
      <div
        class="m-input__content"
        :class="[
          {
            'm-input__content--disabled': disabled,
            'm-input__content--hover': hoverInput,
            '--a11y': appStore.a11y,
          },
          validationMessage?.type
            ? `m-input__content--${validationMessage?.type}`
            : '',
        ]"
        @mouseenter="hoverInput = true"
        @mouseleave="hoverInput = false"
        @dragenter.stop.prevent
        @dragover.stop.prevent
        @drop.stop.prevent="handleFiles"
      >
        <m-icon
          v-if="leadingIcon"
          :id="`m_icon_${id}`"
          :data-test="`test_m_icon_${id}_leading`"
          :icon="leadingIcon"
          :label="label"
          :size="size"
          status="active"
          class="m-icon--leading"
        />
        <input
          v-if="type == 'upload' && typeof value == 'object'"
          :id="id"
          :data-test="`test_m_input_${id}`"
          :value="value.name"
          :placeholder="placeholder"
          class="c-default"
          :class="`type--${size}`"
          readonly
        />
        <input
          v-else
          v-auto-grow
          ref="inputRef"
          :id="id"
          :data-autofocus="autoFocus"
          :data-test="`test_m_input_${id}`"
          data-tour="tour_viewSetup_viewName"
          v-model="value"
          :placeholder="placeholder"
          :class="`type--${size} ${type == 'upload' ? 'min-w-0' : ''}`"
          :type="type == 'password' ? showPassword : type"
          :required="type == 'time'"
          :readonly="disabled"
          :disabled="disabled"
          :min="min"
          :max="max"
          autocomplete="off"
          @keyup.stop="validate"
          @keyup.up="(evt) => $emit('keyup', evt)"
          @keyup.down="(evt) => $emit('keyup', evt)"
          @keyup.enter="resolve"
          @keyup.esc.stop="finishedEditing"
          @blur="finishedEditing"
          v-focus
        />
        <input
          v-if="type == 'upload'"
          :id="`m_input_upload_${id}`"
          ref="fileRef"
          type="file"
          :accept="accept"
          class="d-none min-w-0"
          @change="selectFile"
          @click.stop
          autocomplete="off"
        />
        <div v-if="type == 'number'" class="m-input__arrows">
          <m-icon
            :id="`m_input_${id}_up`"
            :data-test="`test_m_icon_${id}_up`"
            icon="arrow"
            variant="secondary"
            :tooltip="getTooltip(t('general.forms.more'))"
            :size="size"
            :disabled="max && value > max"
            @click="upValue"
          />
          <m-icon
            :id="`m_input_${id}_down`"
            :data-test="`test_m_icon_${id}_down`"
            icon="arrow"
            variant="secondary"
            direction="down"
            :tooltip="getTooltip(t('general.forms.less'))"
            :size="size"
            :disabled="min && value < min"
            @click="downValue"
          />
        </div>

        <m-icon
          v-else-if="showTraillingIconMask"
          :id="`m_icon_${id}`"
          tabindex="-1"
          :data-test="`test_m_icon_${id}_none`"
          icon="none"
          :size="size"
          class="m-icon--trailling"
        />

        <m-icon
          v-else-if="traillingIcon"
          :id="`m_icon_${id}`"
          tabindex="-1"
          :data-test="`test_m_icon_${id}_trailling`"
          :icon="traillingIcon"
          :tooltip="traillingActionTooltip"
          variant="secondary"
          :status="trailingIconStatus"
          :size="size"
          class="m-icon--trailling"
          @click="traillingAction"
        />
        <m-button
          v-if="type == 'upload'"
          :id="`m_icon_${id}`"
          :data-test="`test_m_icon_${id}_upload`"
          type="contained"
          variant="secondary"
          size="xsmall"
          :tooltip="t('general.forms.uploadFile')"
          leadingIcon="upload"
          @click="openFileSelector"
        />
      </div>

      <p
        v-if="validationMessage.label"
        class="m-input__validation"
        :class="`type--${validationMessage.type}`"
      >
        {{ validationMessage.label }}
      </p>
    </div>
  </div>
</template>

<script setup>
/*
 * Monitio Input component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/631111690/Input
 */

import { ref, watch, onMounted, onBeforeUnmount, computed } from "vue";
import { useI18n } from "vue-i18n";

import { useApi } from "@api/api";

import MIcon from "@components/MIcon.vue";
import MButton from "@components/MButton.vue";
import MHint from "@components/MHint.vue";
import { debounce, delay } from "lodash-es";
import { useUserStore } from "@root/store/modules/user";
import { useAppStore } from "@root/store/app";
import { onUnmounted } from "vue";
import { validateEmail } from "@root/utils/validateEmail";

const { api } = useApi();

const props = defineProps({
  id: {
    type: String,
    required: true,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  modelValue: { type: [Number, String, Object] },
  size: {
    type: String,
    default: "default",
    validator(size) {
      if (!["default", "small", "xsmall"].includes(size)) {
        console.error(
          `Invalid prop "size": expected string with value "default", "small" or "xsmall" and got "${size}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/631111690/Input for instructions on how to use the MInput component.`
        );
      }
      return true;
    },
  },
  type: {
    type: String,
    default: "text",
    validator(type) {
      if (
        ![
          "text",
          "number",
          "time",
          "email",
          "password",
          "upload",
          "date",
        ].includes(type)
      ) {
        console.error(
          `Invalid prop "type": expected string with value "text", "number", "time", "email", "password" or "upload" and got "${type}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/631111690/Input for instructions on how to use the MInput component.`
        );
      }
      return true;
    },
  },
  accept: { type: String, default: "image/*" },
  maxlength: { type: [String, Number] },
  min: { type: [String, Number] },
  max: { type: [String, Number] },
  fullwidth: { type: Boolean },
  label: { type: String },
  placeholder: { type: String },
  info: { type: String },
  required: { type: Boolean, default: false },
  validation: { type: [Function, Object] },
  leadingIcon: { type: String },
  disabled: { type: Boolean, default: false },
  autoFocus: { type: Boolean, default: true },
  autoSelect: { type: Boolean, default: false },
  canClear: { type: Boolean, default: true },
  autoGrow: { type: [Boolean, Number], default: false },
});

const emit = defineEmits([
  "update:modelValue",
  "is-valid",
  "resolve",
  "blur",
  "keyup",
]);

const { t } = useI18n();
const userStore = useUserStore();
const appStore = useAppStore();

/** @type {import("vue").Ref<HTMLInputElement|null>} */
const inputRef = ref(null);
const fileRef = ref(null);
const hoverInput = ref(false);
const value = ref(props.modelValue || "");
const passwordLabel = ref("");
const showPassword = ref("password");
const validationMessage = ref({ type: null, label: null });

watch(
  () => value.value,
  (val, oldVal) => {
    if (val != oldVal && props.type != "upload") {
      if (!props.maxlength || val.length <= props.maxlength) {
        emit("update:modelValue", val);
        const vm = validationMessage.value;
        vm.type = null;
        vm.label = null;
      } else {
        emit("update:modelValue", val.slice(0, 48));
        const vm = validationMessage.value;
        vm.type = null;
        vm.label = null;
        validationMessage.value.type = "error";
        validationMessage.value.label = t("general.forms.maxChar", {
          count: props.maxlength,
        });
      }
    }
  },
  { immediate: true }
);

watch(
  () => props.modelValue,
  (val) => {
    if (val != value.value || val.name != val.value) {
      value.value = val;
      const vm = validationMessage.value;
      vm.type = null;
      vm.label = null;
    }
  },
  { immediate: true }
);

watch(
  () => validationMessage.value,
  (val) => {
    emit("is-valid", val.type == null ? true : false);
  },
  { immediate: true }
);

const isValidUrl = (url) => {
  const regex =
    /(?:https?:\/\/)(?:\w+\.)+\w+.*(?:\.(jpg|jpeg|jfif|pjpeg|pjp|gif|png|svg|gif))+/i;
  /*   const pattern = ["http://", "https://", "blob-store://"];
  const imageExt = [".jpg , .jpeg , .jfif , .pjpeg , .pjp", ".gif", ".png"];
  for (const item of pattern) {
    if (url.includes(item)) return true;
  } */

  console.log(regex.test(url));

  return regex.test(url);
  //return false;
};

const handlePaste = (evt) => {
  const paste = evt.clipboardData.getData("text");
  if (isValidUrl(paste)) setTimeout(resolve, 200);
};
onMounted(() => {
  if (!props.modelValue && !props.placeholder) {
    console.error(
      `Missing required prop "placeholder". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/631111690/Input for instructions on how to use the MInput component.`
    );
  }

  if (props.type == "upload")
    inputRef.value.addEventListener("paste", handlePaste);

  if (props.autoSelect) delay(() => inputRef.value.select(), 60);
});

onUnmounted(() => {
  if (props.type == "upload")
    inputRef.value?.removeEventListener("paste", handlePaste);
});

const getTooltip = (content) => {
  return {
    content: content,
    position: "dynamic",
  };
};

const traillingIcon = computed(() => {
  const isChrome = navigator.userAgent.indexOf("Chrome") != -1;
  if (value.value && !props.disabled) {
    if (props.type == "password") {
      return showPassword.value ? "visibility-on" : "visibility-off";
    } else if (props.type == "time") {
      return "schedule";
    } else if (props.type == "date") {
      // If browser is chrome insert monitio icons, otherwise leave native browser icons
      return isChrome ? "calendar" : null;
    } else if (props.type == "upload") {
      return "close";
    } else if (props.canClear.value) {
      return "clear";
    } else {
      return null;
    }
  } else return null;
});

const showTraillingIconMask = computed(() => {
  return !value.value && !props.disabled && traillingIcon.value;
});

const trailingIconStatus = computed(() => {
  if (props.type == "time" || props.type == "date") return "active";
  else return "inactive";
});

const traillingAction = () => {
  if (props.type == "password") {
    if (showPassword.value == "password") {
      showPassword.value = "text";
      passwordLabel.value = t("general.forms.showPassword");
    } else {
      showPassword.value = "password";
      passwordLabel.value = t("general.forms.hidePassword");
    }
    passwordLabel.value = "";
  } else if (traillingIcon.value == "clear" || traillingIcon.value == "close") {
    value.value = "";
    resolve();
    focusInput();
  }
};

const traillingActionTooltip = computed(() => {
  if (passwordLabel.value) {
    return getTooltip(passwordLabel.value);
  } else if (traillingIcon.value === "clear") {
    return getTooltip(t("general.forms.clear"));
  } else return null;
});

const validate = debounce(() => {
  const vm = validationMessage.value;

  if (props.type == "email") {
    const isValidEmail = validateEmail(value.value);
    if (isValidEmail) {
      emit("blur", value.value);
      inputRef.value.blur();
    } else {
      validationMessage.value.type = "error";
      validationMessage.value.label = t("general.errors.invalidEmail");
    }
  } else if (props.type == "number") {
    if (
      props.min &&
      props.max &&
      (value.value < props.min || value.value > props.max)
    ) {
      vm.type = "error";
      vm.label = t("general.errors.numberBetween", {
        min: props.min,
        max: props.max,
      });
    } else if (props.min && value.value < props.min) {
      vm.type = "error";
      vm.label = t("general.errors.numberBigger", { min: props.min });
    } else if (props.max && value.value > props.max) {
      vm.type = "error";
      vm.label = t("general.errors.numberSmaller", { max: props.max });
    }
  } else if (typeof props.validation == "function") {
    const message = props.validation();
    vm.type = message.type;
    vm.label = message.label;
  } else if (props.validation) {
    vm.type = props.validation.type;
    vm.label = props.validation.label;
  }
}, 1000);

const resolve = () => {
  finishedEditing();
  emit("resolve", value.value);
};

const finishedEditing = (evt) => {
  if (props.type == "email") {
    validateEmail();
  } else if (typeof props.validation == "function") {
    const message = props.validation();
    const vm = validationMessage.value;
    vm.type = message.type;
    vm.label = message.label;
  } else if (props.validation) {
    const vm = validationMessage.value;
    vm.type = props.validation.type;
    vm.label = props.validation.label;
  }

  inputRef.value?.blur();
  emit("blur", value.value);
};

onBeforeUnmount(() => {
  passwordLabel.value = null;
});

const upValue = () => {
  if (
    !props.max ||
    (props.max && parseInt(value.value) < parseInt(props.max))
  ) {
    value.value = parseInt(value.value) + 1;
  }
};

const downValue = () => {
  if (
    !props.min ||
    (props.min && parseInt(value.value) > parseInt(props.min))
  ) {
    value.value = parseInt(value.value) - 1;
  }
};

const selectFile = (evt) => {
  if (props.type == "upload" && evt.target.files.length) {
    const file = evt.target.files[0];
    const reader = new FileReader();
    reader.addEventListener("loadend", (evt) => {
      api.workspaces
        .uploadPicture(reader.result)
        .then(({ data }) => {
          evt.target.value = null;
          value.value = { name: file.name, url: data };
          resolve();
        })
        .catch((error) => console.error(error));
    });
    reader.readAsArrayBuffer(file);
  }
};

const handleFiles = (evt) => {
  if (props.type == "upload") {
    const dt = evt.dataTransfer;
    const files = dt.files;
    evt.target.files = files;
    selectFile(evt);
  }
};

const openFileSelector = () => {
  fileRef.value?.click();
};

const focusInput = () => {
  inputRef.value.focus();
};

//tour hints
const displayHints = computed(() => {
  return (
    userStore.ongoingTour.name == "createView" &&
    userStore.ongoingTour.currentStep == 3
  );
});

defineExpose({
  focus: focusInput,
  ref: inputRef,
});

/**
 *
 * @param {Event} evt
 */
const setAutoGrowWidth = (evt, el) => {
  const element = el ?? evt.target;
  const effectiveText = element.value;
  console.log(element, element.value);

  const effectiveFont = window
    .getComputedStyle(element)
    .getPropertyValue("font");
  element.shadowEl.text = element.shadowEl.textContent = effectiveText;
  element.shadowEl.style.font = effectiveFont;

  if (
    typeof props.autoGrow === "number" &&
    element.shadowEl.offsetWidth > props.autoGrow - 150
  ) {
    element.style.width = `${props.autoGrow - 150}px`;
  } else {
    element.style.width = `${element.shadowEl.offsetWidth}px`;
  }
};

const vAutoGrow = {
  mounted: (el) => {
    if (!props.autoGrow) return;
    if (!el.shadowEl) {
      el.shadowEl = document.createElement("span");
      el.shadowEl.style.display = "block";
      el.shadowEl.style.position = "absolute";
      el.shadowEl.style.height = "0";
      el.shadowEl.style.opacity = "0";
      el.shadowEl.style.pointerEvents = "none";
      document.getElementById("m-main").appendChild(el.shadowEl);
    }
    const textCheck = setInterval(() => {
      if (el.value) {
        setAutoGrowWidth(null, el);
        clearInterval(textCheck);
      }
    }, 1);
    el.addEventListener("input", setAutoGrowWidth);
  },
  beforeUnmount: (el) => {
    document.getElementById("m-main").removeChild(el.shadowEl);
    el.removeEventListener("input", setAutoGrowWidth);
  },
};
</script>

<style scoped lang="scss">
::-webkit-inner-spin-button {
  display: none;
}
.min-w-0 {
  min-width: 0;
}
</style>
